import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollReset = () => {
  const location = useLocation();
  var pageContainer = document.getElementsByClassName('css-uyex22');

  useEffect(() => {
    pageContainer[0]?.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default useScrollReset;
