import { createSlice } from '@reduxjs/toolkit';
import ReactGA from 'react-ga';

const initialState = {
  loader: false,
  message: ''
};

const FaqSlice = createSlice({
  name: 'faqSlice',
  initialState,
  reducers: {
    sendMessage(state) {
      state.loader = true;
    },
    sendMessageSuccess(state, action) {
        ReactGA.event({
            category: 'User',
            action: 'Sent a Message'
          });
      state.message = action.payload.message[0];
      state.loader = false;
    },
    sendMessageFailure(state) {
      state.loader = false;
      state.isAuthenticated = false;
    }
  }
});

export const {
  sendMessage,
  sendMessageSuccess,
  sendMessageFailure
} = FaqSlice.actions;

export default FaqSlice.reducer;
