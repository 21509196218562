import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'app/store';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { SettingsProvider } from 'contexts/SettingsContext';
import 'i18n';
import Boot from 'boot';
import AppRouter from 'routes';

const DashApp = () => (
  <ReduxProvider store={store}>
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <AppRouter />
        </SettingsProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  </ReduxProvider>
);

Boot()
  .then(() => DashApp())
  .catch((error) => console.error(error));

export default DashApp;
