import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'app/rootReducers';
import rootSaga from 'app/rootSaga';

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware) });
sagaMiddleware.run(rootSaga);

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('app/rootReducers', () => store.replaceReducer(rootReducer));
}
export default store;
