import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  IconButton,
  CardMedia
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { appLogo, downloadAppStore, faceBook, facebookIcon, instaIcon, newAppLogo, twitterIcon } from 'features/assets';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';
import { IOS_APP_URL } from './utils/staticData';

const sections = [
  {
    title: '',
    links: [
      {
        title: 'Students',
        href: '/register'
      },
      {
        title: 'Universities',
        href: '/request_demo'
      },
      {
        title: 'Services',
        href: '/what_we_offer'
      },
      {
        title: 'About',
        href: '/about'
      },
      {
        title: 'Support',
        href: '/faq'
      }
    ]
  }
];

const Footer = (props) => (
  <Box
    sx={{
      backgroundColor: '#fff',
      pb: 2,
      pt: {
        md: 2,//15,
        xs: 6
      }
    }}
    {...props}
  >
    <Container maxWidth="lg">
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          color="inherit"
          component={RouterLink}
          to="/"
        >
          <img src={newAppLogo} alt="pnp_icon" className="img-responsive" height="70" width="170" />
        </IconButton>
      </Grid>  
      <Grid
        container
        md={12}
        sx={{
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Grid
          item
          lg={3}
          md={3}
          xl={3}
          xs={12}
        >
        {
          sections.map((section, index) => (
          <Grid
            item
            key={section.title}
            md={3}
            sm={4}
            sx={{
              order: {
                md: index + 2,
                xs: index + 1
              }
            }}
            xs={12}
          >
            <Typography
              color="textSecondary"
              variant="overline"
            >
              {section.title}
            </Typography>
            <List disablePadding>
              {section.links.map((link) => (
                <ListItem
                  disableGutters
                  key={link.title}
                  sx={{
                    pb: 0,
                    pt: 1
                  }}
                >
                  <ListItemText
                    primary={(
                      <Link
                        component={RouterLink}
                        to={link.href}
                        color="textPrimary"
                        variant="subtitle2"
                        style={{
                          color: '#333333'
                        }}
                      >
                        {link.title}
                      </Link>
                    )}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
        </Grid>

        <Grid
          item
          md={3}
          sm={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems:{
              md: 'flex-end',
              xs: 'center'
            },
            // 'flex-end',
            order: {
              md: 1,
              xs: 4
            }
          }}
          xs={12}
        >
          <IconButton
            color="inherit"
            sx={{
              px: 0
            }}
            onClick={()=>window.open(IOS_APP_URL)}
          >
            <img src={downloadAppStore} alt="expand_icon" />
          </IconButton>
          <Box
            sx={{
              width: '52%',
              direction: 'ltr',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <IconButton
            color="inherit"
            sx={{
              px: 0
            }}
            onClick={()=>window.open('https://www.instagram.com/pickupnpark_/?hl=en')}
          >
            <img src={instaIcon} alt="expand_icon" />
          </IconButton>

          <IconButton
            color="inherit"
            sx={{
              px: 0
            }}
            onClick={()=>window.open('https://www.facebook.com/')}
          >
            <img src={facebookIcon} alt="expand_icon" />
          </IconButton>

          <IconButton
            color="inherit"
            sx={{
              px: 0
            }}
            onClick={()=>window.open('https://twitter.com/pickupnpark')}
          >
            <img src={twitterIcon} alt="expand_icon" />
          </IconButton>
          </Box>
          </Grid>
      </Grid>  
      <Grid
        // md={6}
        container
        style={{
          display:'flex',
          justifyContent:'center'
        }}
        sx={{
          display: {
            md: 'block',
            xs: 'none'
          }
        }}
      >
        <Grid
          container
          md={4}
          sx={{
            direction: 'ltr',
            display: 'flex',
            justifyContent: 'space-around',
            mt: {
              xs: 2,
              sm: 2
            }
          }}
        >
        <Link
          // component={RouterLink}
          target="_blank"
          href="/privacy_policy"
          // to="/privacy_policy"
          underline="always"
          variant="footerLink"
          sx={{
            fontWeight: 400,
            fontSize: 12
          }}
        >
          Privacy Policy
        </Link>
        <Link
          target="_blank"
          component={RouterLink}
          to="/terms_of_use"
          underline="always"
          variant="footerLink"
          sx={{
            fontWeight: 400,
            fontSize: 12
          }}
        >
          Terms of Use
        </Link>
        <Link
          href="#"
          underline="always"
          variant="footerLink"
          sx={{
            fontWeight: 400,
            fontSize: 12
          }}
        >
          Accessibility
        </Link>
        </Grid>  
      </Grid>
      <Divider
        sx={{
          borderColor: '#333333',//(theme) => alpha(theme.palette.primary.contrastText, 0.12),
          my: 1
        }}
      />
      <Box
        sx={{
          width: '100%',
          justifyContent: 'center',
          display: 'flex'
        }}
      >
        <Typography
          color="#333333"
          variant="caption"
        >
          <Typography
            color="#333333"
            variant="caption"
            style={{
              fontSize: '14px',
              marginRight: 3
            }}
          >
            ©️
          </Typography>
           2021 Apne Technologies, Inc.
        </Typography>
      </Box>
    </Container>
  </Box>
);

export default Footer;