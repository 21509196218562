import { Suspense, lazy } from 'react';
// import DashboardLayout from './components/dashboard/DashboardLayout';
// import LoadingScreen from './components/LoadingScreen';
import DashboardLayout from 'common/sharedComponents/DashboardLayout';
import LoadingScreen from 'common/LoadingScreen';
import {
  useRoutes
} from 'react-router-dom';
import useScrollReset from 'common/utils/scrollToTop';
// import TermsnConditions from 'Pages/TermsConditions/TermsConditions';
// import RequestDemo from 'Pages/RequestDemo/RequestDemo';
// import WhatWeOffer from 'Pages/WhatWeOffer';
// import About from 'Pages/AboutPage';
// import FaqPage from 'Pages/Faq/FaqPage';
// const content = useRoutes(routes);

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Browse pages

const Landing = Loadable(lazy(() => import('Pages/Landing/LandingPage')));
const RegisterNow = Loadable(lazy(() => import('Pages/RegisterNow/RegisterNow')));
const RequestDemo = Loadable(lazy(() => import('Pages/RequestDemo/RequestDemo')));
const WhatWeOffer = Loadable(lazy(() => import('Pages/WhatWeOffer')));
const About = Loadable(lazy(() => import('Pages/About/AboutPage')));
const FaqPage = Loadable(lazy(() => import('Pages/Faq/FaqPage')));
const PrivacyPolicy = Loadable(lazy(() => import('Pages/PrivacyPolicy/PrivacyPolicy')));
const TermsConditions = Loadable(lazy(() => import('Pages/TermsConditions/TermsnConditions')));

const App = () => {

  useScrollReset();

    const routes = useRoutes([
        {
            path: '',
            element: (
            <DashboardLayout />
            ),
            children: [
            {
                path: '',
                element: <Landing />
            },
            {
              path: '/register',
              element: <RegisterNow />
            },
            {
              path: '/request_demo',
              element: <RequestDemo />
            },
            {
              path: '/what_we_offer',
              element: <WhatWeOffer />
            },
            {
              path: '/about',
              element: <About />
            },
            {
              path: '/faq',
              element: <FaqPage />
            },
            {
              path: '/privacy_policy',
              element: <PrivacyPolicy />
            },
            {
              path: '/terms_of_use',
              element: <TermsConditions />
            }
          ]
        }
        ]);

    return routes;
};

export default App;