import { merge } from 'lodash';
import { gradientColors, THEMES } from 'common/utils/constants';
import { lightShadows, darkShadows } from 'theme/shadows';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

const baseOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#FFF'
        }
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 700,
          fontSize: '16px',
          background: '#15166B',
          color: '#fff',
          borderRadius: 20,
          height: 40,
          width: '12rem',
          boxShadow: '6px 6px 6px #00000029'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 700,
          fontSize: '16px',
          width: '200px !important',
          boxShadow: '-1px 3px 6px -1px rgba(0,0,0,0.81);',
          // fontFamily: 'Poppins, serif',
          // fontFamily: "'Poppins', sans-serif",
          // fontWeight:'black'
          // width: 150,
          // height: 35,
          // boxShadow: '6px 6px 6px #00000029'
        },
        contained: {
          borderRadius: 17,
          background: '#15166B'
        },
        containedAction: {
          borderRadius: 20,
          // background: '#fff',
          // color: '#15166B',
          // width: 150,
          height: 40,
          boxShadow: '6px 6px 6px #00000029'
        }
      }
    },
    MuiOutlinedInput:{
      styleOverrides: {
        input: {
          fontFamily: "'Poppins', sans-serif !important"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 500,
          color: '#15166B'
        },
        h2: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 600
        },
        h3: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 600
        },
        h4: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 600
        },
        h5: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 600
        },
        h6: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 500
        },
        body2: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 300
        },
        body: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 300
        },
        body1: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 300
        },
        subtitle1: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 400,
          color: '#15166B'
        },
        subtitle2: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 500,
          color: '#15166B'
        },
        subtitle: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 500,
          color: '#15166B'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 12
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#333333',
          height: 20,
          width: 20
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: 10,
          color: '#000',
          // fontWeight: 600,
          textDecoration: 'none',
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 500,
          color: '#15166B !important',
          fontSize: 14,
          '&:hover': {
            color: '#333333'
          }
        },
        footerLink: {
          fontSize: 10,
          color: '#333333',
          fontWeight: 600,
          textDecoration: 'none',
          '&:hover': {
            color: '#333333'
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%'
        },
        '#root': {
          height: '100%'
        },
        '#nprogress .bar': {
          zIndex: '2000 !important'
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      },
      styleOverrides: {
        title: {
          fontSize: 13,
          paddingLeft: 7
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          // border: '1px solid red',
          height: 38,
          background: '#fff',
          boxShadow: '6px 6px 6px #00000029'
          // "-webkit-box-shadow": 'none'
        },
        input: {
          height: 16,
          borderRadius: 20,
          paddingLeft: '18px',
          // border: '1px solid red',
          color: '#333333',
          backgroundColor: '#fff',
          // boxShadow: '0 0 0 100px #fff inset !important',
          boxShadow: '-1px 3px 6px -1px rgba(0,0,0,0.81);',
          textFillColor: '#333333 !important'
          // "webkit-text-fill-color": '#000 !important'
          // "-webkit-box-shadow": 'none'
        },
        notchedOutline: {
          border:'none'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          // height: 80,
          borderRadius: '50px !important',
          boxShadow: '8px 8px 6px #00000029',
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          height: 50,
          // borderRadius: '50px !important'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          borderRadius: '20px !important',
          height: '100px !important'
        },
        inputMultiline: {
          borderRadius: '5px !important',
          height: '90px !important',
          boxShadow:'none !important',
          paddingLeft: '7px !important'
          // border: '1px solid red'
        }
      }
    }
  },
  
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem'
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem'
    },
    overline: {
      fontWeight: 600
    }
  }
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            boxShadow: '6px 6px 6px #00000029',
            borderRadius: 50,
            fontFamily: "'Poppins', sans-serif",
            border: '1px solid red'
          },
          input: {
            borderRadius: 50,
            fontFamily: "'Poppins', sans-serif",
            textFillColor: '#333333 !important',
            boxShadow: '6px 6px 6px #00000029',
            color: '#333333',
            border: '1px solid red',
            boxShadow: '0 0 0 100px #fff inset !important',
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            },
            '&::hover': {
              border: '1px solid gold !important'
            }
          },
          multiline:{
            border: '1px solid gold !important'
            
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#f4f5f7',
        paper: '#F7FAF9'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#5664d2'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: lightShadows
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: '#F7FAF9',
        paper: '#F7FAF9'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#688eff'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#333333',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: darkShadows
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#fff'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: darkShadows
  }
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    // eslint-disable-next-line no-console
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createTheme(merge({}, baseOptions, themeOptions, {
    ...(config.roundedCorners && {
      shape: {
        borderRadius: 16
      }
    })
  }, {
    direction: config.direction
  }));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
