import PropTypes from 'prop-types';
import { AppBar, IconButton, Toolbar, CardMedia, Button, Box } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import { appLogo, newAppLogo } from 'features/assets';
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';
import { MenuIcon } from 'icons';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 90, height: 90, background:'#F7FAF9' }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              md: 'none',
              sm: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          component={RouterLink}
          to="/"
        >
          <img src={newAppLogo} alt="pnp_icon" className="img-responsive" height="80" width="190" />
        </IconButton>
        <Box
          sx={{
            flexGrow: 2,
          }}
        />
        <Box
          sx={{
            flexGrow: 1,
            display: {
              md: 'flex',
              lg: 'flex',
              sm:'flex',
              xs:'none'
            },
            // display:'flex',
            justifyContent:'space-evenly'
          }}
        >
          <Button
            color="primary"
            variant="text"
            component={RouterLink}
            to="/register"
            sx={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              color: '#15166B',
              boxShadow: 'none',
              width:'80px !important'
            }}
          >
            Students
          </Button>
          <Button
            color="primary"
            variant="text"
            component={RouterLink}
            to="/request_demo"
            sx={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              color: '#15166B',
              boxShadow: 'none',
              width:'80px !important'
            }}
          >
            Universities
          </Button>
          <Button
            color="primary"
            variant="text"
            component={RouterLink}
            to="/what_we_offer"
            sx={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              color: '#15166B',
              boxShadow: 'none',
              width:'80px !important'
            }}
          >
            Services
          </Button>
          <Button
            color="primary"
            variant="text"
            component={RouterLink}
            to="/about"
            sx={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              color: '#15166B',
              boxShadow: 'none',
              width:'80px !important'
            }}
          >
            About
          </Button>
          <Button
            color="primary"
            variant="text"
            component={RouterLink}
            to="/faq"
            sx={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              color: '#15166B',
              boxShadow: 'none',
              width:'80px !important'
            }}
          >
            Support
          </Button>
        </Box>  
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
