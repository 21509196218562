import { all, put, call, takeLatest } from 'redux-saga/effects';
import {
  demoRequest,
  demoRequestSuccess,
  demoRequestFailure
} from 'Pages/RequestDemo/RequestDemoSlice';
import { handleException } from 'app/apiHelper';
import { postRequest } from 'app/axiosClient';

function* demoRequestAPI(action) {
  try {
    const response = yield call(() => postRequest('demo-request', action.payload));
    yield put(demoRequestSuccess(response.data));
  } catch (error) {
      yield put(demoRequestFailure());
      handleException(error);
    }
}

export default function* rootSaga() {
  yield all([
    takeLatest(demoRequest, demoRequestAPI)
  ]);
}
