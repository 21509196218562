import { all, put, call, takeLatest } from 'redux-saga/effects';
import {
  sendMessage,
  sendMessageSuccess,
  sendMessageFailure
} from 'Pages/Faq/FaqSlice';
import { handleException } from 'app/apiHelper';
import { postRequest } from 'app/axiosClient';

function* sendMessageApi(action) {
    try {
      const response = yield call(() => postRequest('visitor-message', action.payload));
      yield put(sendMessageSuccess(response.data));
    } catch (error) {
      yield put(sendMessageFailure());
      handleException(error);
    }
  }

export default function* rootSaga() {
  yield all([
    takeLatest(sendMessage, sendMessageApi)
  ]);
}
