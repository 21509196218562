import { all } from 'redux-saga/effects';
// import callsDashboardSaga from 'features/CallsDashboard/CallsDashboardAPI';
import registerSaga from 'Pages/RegisterNow/RegisterApis';
import requestDemoSaga from 'Pages/RequestDemo/RequestDemoApi';
import faqSaga from 'Pages/Faq/FaqApi';

// Here you can include all the saga which you write for components

export default function* rootSaga() {
  yield all([
    registerSaga(),
    requestDemoSaga(),
    faqSaga()
  ]);
}
