import toast from 'react-hot-toast';
import { serverErrorCodes } from 'common/utils/constants';
import history from 'common/utils/history';

export function handleException(error) {
  const { response } = error;
  if (response) {
    if (serverErrorCodes.includes(response.status)) {
      toast.error(error.response.errors);
      window.BugsnagClient.notify(error);
      toast.error(response.data?.error[0]?.code);
    } else if (response.status === 404) {
      history.push('/404');
      toast.error(response.data?.error[0]?.code);
    } else {
      // need to get error message from helper with error code
      toast.error(response.data?.error[0]?.code);
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
