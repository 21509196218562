import React from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Toaster } from 'react-hot-toast';
import useSettings from 'common/hooks/useSettings';
import { createCustomTheme } from 'theme';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import App from './app-routes';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { GOOGLE_ANALYTICS_ID } from 'utils/analyticsHelper';
// import useScrollReset from 'common/utils/scrollToTop';

const AppRouter = () => {
  const { settings } = useSettings();

  // useScrollReset();

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
  },[]);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      <Router>
        <App/>
        {/* {content} */}
      </Router>
    </ThemeProvider>
  );
};

export default AppRouter;
