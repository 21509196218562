import registerSlice from 'Pages/RegisterNow/RegisterSlice';
import requestDemoSlice from 'Pages/RequestDemo/RequestDemoSlice';
import faqSlice from 'Pages/Faq/FaqSlice';

// Include all the reducer to combine and provide to configure store.

const rootReducer = {
  registerSlice,
  requestDemoSlice,
  faqSlice
};

export default rootReducer;
