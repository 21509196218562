import { createSlice } from '@reduxjs/toolkit';
import ReactGA from 'react-ga';

const initialState = {
  loader: false,
  message: '',
  allCalls: [],
  transferredCalls: []
};

const RegisterSlice = createSlice({
  name: 'registerSlice',
  initialState,
  reducers: {
    login(state) {
      state.loader = true;
    },
    loginSuccess(state, action) {
        ReactGA.event({
          category: 'User',
          action: 'Entered a school detail'
        });
      state.message = action.payload.message[0];
      state.loader = false;
    },
    loginFailure(state) {
      state.loader = false;
      state.isAuthenticated = false;
    }
  }
});

export const {
  login,
  loginSuccess,
  loginFailure
} = RegisterSlice.actions;

export default RegisterSlice.reducer;
