export const IOS_APP_URL = 'https://apps.apple.com/US/app/id1594903820?mt=8';
export const ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=com.pickupnparkone';

export const DO_MORE =  [
    {
        id: 1,
        iconName: "timeSave",
        title: "Save Time",
        customIconWidth: '10%',
        description: "Our parking solution saves you the headache and time wasted on securing a parking spot. What do you want to do with all that free time?"
    },
    {
        id: 2,
        iconName: "saveMoney",
        title: "Save Money",
        customIconWidth: '16%',
        description: "Why spend $75* on other ride-hailing services when you can travel for free? Download our app to book rides for free, starting today!"
    },
    {
        id: 3,
        iconName: "socialize",
        title: "Socialize",
        customIconWidth: '15%',
        description: "Make friends on the way to school or on the way back to your car! Pickup N’ Park provides more opportunities for students to make new connections."
    },
    {
        id: 4,
        iconName: "sustainability",
        title: "Sustainability",
        customIconWidth: '15%',
        description: "Did you know that we can make 2 round trips to the moon with the amount of gas wasted on finding parking in LA each year? Help reduce carbon emissions with Pickup N’ Park!"
    },
    {
        id: 5,
        iconName: "security",
        title: "Enhanced Security",
        customIconWidth: '15%',
        description: "Our three-tier security verification system ensures only students from partnered universities can log into our app."
    },
    {
        id: 6,
        iconName: "buildCommunity",
        title: "Build a Community",
        customIconWidth: '18%',
        description: "We want to bring students together to help each other succeed. Give a ride to a fellow student on the way to school, or help them secure a parking spot."
    }
]

export const DEMO_REQ =  [
    {
        id: 10,
        iconName: 'mobilityAnalytics',
        customIconWidth: '17%',
        title: "Mobility Analytics",
        description: "We build proprietary, customized software for universities to curb congestion and parking overflow during peak hours of operations"
    },
    {
        id: 9,
        iconName: 'saveCost',
        customIconWidth: '17%',
        title: "Cost Efficient",
        description: "We help universities save cost by maximizing the efficiency of existing mobility resources to avoid building costly parking structures and new shuttle services"
    },
    {
        id: 8,
        title: "Security Compliant",
        customIconWidth: '15%',
        iconName: 'security',
        description: "We are ISO 27001 Compliant. We have completed HECVAT and VPAT documentation, and our ISO team can complete all required IRT documents"
    },
    {
        id: 7,
        title: "Custom-built",
        customIconWidth: '20%',
        iconName: 'customBuilt',
        description: "Get customized transportation solutions tailored to your school’s logistics requirements"
    },
    {
        id: 6,
        title: "Increase Engagement",
        customIconWidth: '25%',
        iconName: 'increaseEngagement',
        description: "Use our app to notify students with important announcements and increase student engagement"
    },
    {
        id: 5,
        title: "Sustainability Report",
        customIconWidth: '16%',
        iconName: 'sustainabilityReport',
        description: "We provide consolidated reports on GHG reduction impact our app has made within your university"
    }
]

export const ABOUT_US =  [
    {
        id: 2,
        title: "Student first",
        iconName:'studentFirst',
        iconWidth: '18%',
        description: "Always put students first, and strive to provide student-centered solutions"
    },
    {
        id: 4,
        title: "Sustainability",
        iconName:'sustainability',
        iconWidth: '13%',
        description: "Provide services that helps reduce green house gas emissions"
    },
    {
        id: 6,
        iconName:'campusConnect',
        iconWidth: '23%',
        title: "Campus Connectivity",
        description: "Find ways to bring students together and increase campus connectivity"
    }
];

export const FAQLIST =  [
    {
        id: 1,
        title: "Why can’t I sign up with my email?",
        description: "Make sure you are using your @ucr.edu email. Make sure you click on the link that is sent to that email."
    },
    {
        id: 2,
        title: "When can the other user track my location?",
        description: "Locations are only shown when both users have enabled their location. Then, 15 minutes prior to pickup time the location of the other user can be viewed through the track ride button until the ride has been completed."
    },
    {
        id: 3,
        title: "How do I find a parking spot?",
        description: "Go to the driver tab and select a user that has a pickup time that matches when you will come to school. Be sure that the lot that they park in is a lot that you can park in as well."
    },
    {
        id: 4,
        title: "How do I know that a driver is coming?",
        description: "Locations are only shown when both users have enabled their location. Then, 15 minutes prior to pickup time the location of the other user can be viewed through the track ride button until the ride has been completed."
    },
    {
        id: 5,
        title: "How do I get a ride back to my car?",
        description: "Go to the passenger tab and fill out the required information. When a driver sees your request he or she will accept it and you can confirm that you want that person to pick you up for a ride back to your car. For best results fill out this information as soon as you get to school."
    }
];

export const OUR_TEAM =  [
    {
        id: 1,
        avatar: "bharatRai",
        name: "Bharat Rai",
        title: 'Founder',
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore"
    },
    {
        id: 2,
        avatar: "pardipRai",
        name: "Pardip Rai",
        title: 'CTO',
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore"
    },
    {
        id: 3,
        avatar: "ishanMadan",
        name: "Ishaan Madan",
        title: 'Team Leader',
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore"
    },
    {
        id: 4,
        avatar: "delaney",
        name: "Delaney Santos",
        title: 'UI/UX designer',
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore"
    },
    {
        id: 5,
        avatar: "lena",
        name: "Lena Kim",
        title: 'Data Scientist',
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore"
    },
    {
        id: 6,
        avatar: "adriMukerji",
        name: "Adri Mukherjee",
        title: 'Product Marketing Analyst',
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore"
    },
    {
        id: 7,
        avatar: "ryanAbunan",
        name: "Ryan Abunan",
        title: 'Marketing Analyst',
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore"
    },
    {
        id: 8,
        avatar: "akshaySingh",
        name: "Akshay Singh",
        title: 'Frontend Developer',
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore"
    },
    {
        id: 9,
        avatar: "pradeepFarthyal",
        name: "Pradeep Farthyal",
        title: 'Backend Developer',
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore"
    },
]

export const PHASE_DATA = [
    {
      id: 'phase1',
      title: 'Get started using Pickup N’ Park',
      steps: [
        {
          step_id: 'p11',
          step_title: "Download the app",
          step_img: 'appDownload'
        },
        {
          step_id: 'p12',
          step_title: "Create an account",
          step_img: 'appSignup'
        },
        {
          step_id: 'p13',
          step_title: "Choose what to use the app",
          step_img: 'appRole'
        },
        {
          step_id: 'p14',
          step_title: "Choose how to use the parking or carpool options",
          step_img: 'appDashboard'
        }
      ]
    },
    {
      id: 'phase2',
      title: 'Schedule a Ride',
      steps: [
        {
          step_id: 'p21',
          step_title: "Choose a Carpool or Parking Ride",
          step_img: 'appDashboard'
        },
        {
          step_id: 'p22',
          step_title: "Schedule your Ride",
          step_img: 'appRideSchedule'
        },
        {
          step_id: 'p23',
          step_title: "Check Pending Ride",
          step_img: 'appPendingRide'
        },
        {
          step_id: 'p24',
          step_title: "Confirm a Ride",
          step_img: 'appConfirmRequest'
        }
      ]
    },
    {
      id: 'phase3',
      title: 'Complete a Ride',
      steps: [
        {
          step_id: 'p31',
          step_title: "Check back in 15 minutes before your Ride",
          step_img: 'appRideConfirmed'
        },
        {
          step_id: 'p32',
          step_title: "Start your Ride",
          step_img: 'appStartRide'
        },
        {
          step_id: 'p33',
          step_title: "End you Ride",
          step_img: 'appEndRide'
        },
        {
          step_id: 'p34',
          step_title: "Rate your driver",
          step_img: 'appRate'
        }
      ]
    }
  ];