import 'nprogress/nprogress.css';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import DashApp from 'DashApp';

ReactDOM.render(
  <StrictMode>
    <DashApp />
  </StrictMode>,
  document.getElementById('root')
);
