import { createSlice } from '@reduxjs/toolkit';
import ReactGA from 'react-ga';

const initialState = {
  loader: false,
  message: ''
};

const RequestDemoSlice = createSlice({
  name: 'requestDemoSlice',
  initialState,
  reducers: {
    demoRequest(state) {
      state.loader = true;
    },
    demoRequestSuccess(state, action) {
      ReactGA.event({
        category: 'User',
        action: 'Requested a Demo'
      });
      state.message = action.payload.message[0];
      state.loader = false;
    },
    demoRequestFailure(state) {
      state.loader = false;
      state.isAuthenticated = false;
    }
  }
});

export const {
  demoRequest,
  demoRequestSuccess,
  demoRequestFailure
} = RequestDemoSlice.actions;

export default RequestDemoSlice.reducer;
