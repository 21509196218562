const constants = {
  HOST_URL: process.env.REACT_APP_HOST_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
};

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const gradientColors = {
  PURPLE: 'linear-gradient(90deg, hsla(216, 84%, 44%, 1) 0%, hsla(228, 53%, 52%, 1) 27%, hsla(274, 56%, 63%, 1) 67%)',
  GREEN: 'linear-gradient(90deg, hsla(79, 66%, 70%, 1) 0%, hsla(90, 54%, 67%, 1) 50%, hsla(114, 38%, 62%, 1) 100%)',
  GRAY: 'linear-gradient(90deg, hsla(0, 0%, 66%, 1) 0%, hsla(197, 16%, 65%, 1) 50%, hsla(195, 28%, 58%, 1) 100%)',
  INACTIVE_BLACK: 'linear-gradient(151deg, rgba(60,58,59,0.8663974719101124) 17%, rgba(46,44,45,1) 56%, rgba(33,33,33,1) 81%)'
};

export const ItemTypes = {
  CARD: 'card'
};

export const serverErrorCodes = [500, 501, 502, 503, 504, 505];

export const unAuthorizedErrorCode = 401;

export default constants;
// add to gitignore
